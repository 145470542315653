import React from "react";
import "../Estilos/estilosGen.css"

export default function pie_Copyright(){
    const copyright = "Buiding Continuity 2023 Copyright © Todos los derechos reservados"
    return (
        <div>
            <footer>
                <p>{copyright}</p>
            </footer>
        </div>
    );
}